<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="#"
        target="_blank"
      >Plan Your Visit</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
    <SupportModal />
  </p>

</template>

<script>

import SupportModal from '@core/layouts/components/SupportModal'
import { BLink } from 'bootstrap-vue'

export default {
  components: { SupportModal, BLink },
}
</script>

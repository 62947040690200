<template>
  <div class="navbar-container d-flex content align-items-center flex-wrap">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav
      v-if="user"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav">
            <p class="user-name mb-0">
              Welcome,
              <feather-icon
                icon="UserIcon"
                size="21"
                class="text-primary"
                style="margin-right:0.25rem"
              />
              <span class="font-weight-bolder">{{ user.name }}</span>
              <span
                v-if="user.is_test_user"
                style="color: red;"
              >
                T
              </span>
            </p>
            <!--                        <span class="user-status">Admin</span>-->
          </div>
          <!--                    <b-avatar-->
          <!--                            size="40"-->
          <!--                            variant="light-primary"-->
          <!--                            badge-->
          <!--                            :src="require('@/assets/images/avatars/')"-->
          <!--                            class="badge-minimal"-->
          <!--                            badge-variant="success"-->
          <!--                    />-->
        </template>

        <!--                <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--                    <feather-icon-->
        <!--                            size="16"-->
        <!--                            icon="UserIcon"-->
        <!--                            class="mr-50"-->
        <!--                    />-->
        <!--                    <span>Profile</span>-->
        <!--                </b-dropdown-item>-->

        <!--                <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--                    <feather-icon-->
        <!--                            size="16"-->
        <!--                            icon="MailIcon"-->
        <!--                            class="mr-50"-->
        <!--                    />-->
        <!--                    <span>Inbox</span>-->
        <!--                </b-dropdown-item>-->

        <!--                <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--                    <feather-icon-->
        <!--                            size="16"-->
        <!--                            icon="CheckSquareIcon"-->
        <!--                            class="mr-50"-->
        <!--                    />-->
        <!--                    <span>Task</span>-->
        <!--                </b-dropdown-item>-->

        <!--                <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--                    <feather-icon-->
        <!--                            size="16"-->
        <!--                            icon="MessageSquareIcon"-->
        <!--                            class="mr-50"-->
        <!--                    />-->
        <!--                    <span>Chat</span>-->
        <!--                </b-dropdown-item>-->

        <!--                <b-dropdown-divider/>-->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BAvatar, BDropdownDivider, BDropdownItem, BLink, BNavbarNav, BNavItemDropdown,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import UserDropdown from '@core/layouts/components/app-navbar/components/UserDropdown.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/authUser',
      // ...
    }),
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_API_URL,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },

}
</script>
<style>
[dir] .main-menu.menu-light .navigation{
  max-height: 65vh;
  overflow: auto;
}
</style>

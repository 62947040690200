<template>
  <div
    v-if="user && user.trial_ends_at"
    class="trial-left-container"
    @click="goToSelectPlan"
  >
    <span>
      {{ daysOfTrialLeft }}
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getNumberOfDaysLeft } from '@/utils/helpers'
import store from '@/store'

// TODO: this element is not visible even though it's rendered

export default {
  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),
    daysOfTrialLeft() {
      return getNumberOfDaysLeft(store.getters['auth/authUser'])
    },
  },
  methods: {
    goToSelectPlan() {
      this.$router.push({ name: 'select-plan' })
    },
  },
}
</script>
<style scoped>
.trial-left-container {
  position: fixed;
  bottom: 35px;
  left: 0;
  padding: 15px 15px 25px 15px;
  background: #f9f9f9;
  text-align: center;
  width: 100%;
  cursor:pointer;
}

.trial-left-container span {
  color: #5e5873;
  cursor:pointer;
}
</style>

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Contacts',
    route: 'contacts',
    icon: 'UsersIcon',
  },
  {
    title: 'Campuses',
    route: null,
    icon: 'MapIcon',
    children: [
      {
        title: 'All campuses',
        route: 'locations',
        icon: 'FolderIcon',
      },
      {
        title: 'Add campus',
        route: 'new-location',
        icon: 'PlusCircleIcon',
      },
    ],
  },
  {
    title: 'Communication',
    route: 'list-communication-templates',
    icon: 'MessageCircleIcon',
  },
  {
    title: 'Widgets',
    icon: 'GridIcon',
    route: null,
    children: [
      {
        title: 'Visitor Form',
        route: 'edit-cta-widget',
        icon: 'GridIcon',
      },
      {
        title: 'Thank You Message',
        route: 'edit-scheduled-widget',
        icon: 'GridIcon',
      },
    ],
  },
  {
    title: 'Integrations',
    route: 'integrations',
    icon: 'Link2Icon',
    children: [
      {
        title: 'MailChimp',
        route: 'mailchimp',
        icon: 'Link2Icon',
        icon2: 'PowerIcon',
        additionalClass: 'mailchimp_integration',
      },
      {
        title: 'Planning Center',
        route: 'planning-center',
        icon: 'Link2Icon',
        icon2: 'PowerIcon',
        additionalClass: 'planning_center',
      },
    ],
  },
  {
    title: 'Installation',
    route: 'installation',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Account',
    route: null,
    icon: 'UserIcon',
    children: [
      {
        title: 'General',
        route: 'account',
        icon: 'GlobeIcon',
      },
      {
        title: 'Change Password',
        route: 'change-password',
        icon: 'LockIcon',
      },
      {
        title: 'Billing - set method',
        route: 'set-payment-method',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Billing - select plan',
        route: 'select-plan',
        icon: 'CreditCardIcon',
      },
    ],
  },
  {
    title: 'Training<br><span style="font-size: 0.85rem;">Password: University22</span>',
    href: 'https://www.visitplanner.church/university',
    icon: 'YoutubeIcon',
  },
]

<template>
  <div>
    <div class="suggest-feature-link">
      <b-link
        id="toggle-modal-feature"
        title="Suggest a feature"
        @click="openSuggestFeatureModal()"
      >
        Suggest a feature
      </b-link>
    </div>
    <b-modal
      id="modal-feature"
      ref="modal-feature"
      cancel-variant="outline-secondary"
      ok-title="Send message"
      cancel-title="Close"
      centered
      title="Suggest a feature"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer
        ref="supportFormValidator"
        #default="{invalid}"
      >
        <b-form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            label-for="email"
            label="Email:"
          >
            <validation-provider
              #default="{ errors }"
              ref="email"
              name="Email"
              vid="email"
              rules="required"
            >
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                placeholder="Email Address"
                :state="errors.length > 0 ? false:null"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Message"
            label-for="message"
          >
            <validation-provider
              #default="{ errors }"
              ref="message"
              name="Message"
              vid="message"
              rules="required"
            >
              <b-form-textarea
                id="message"
                v-model="message"
                :state="errors.length > 0 ? false:null"
                placeholder="Write your message"
                required
                rows="10"
                style="height:100%;"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton, BLink, VBModal, BForm, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required } from '@validations'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BLink,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      message: '',
      email: '',
      isSubmitting: false,
      modalInitialized: false,
    }
  },
  methods: {
    openSuggestFeatureModal() {
      this.resetModal()
      this.$bvModal.show('modal-feature')
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    resetModal() {
      if (!this.modalInitialized) {
        const user = store.getters['auth/authUser']
        this.email = user.email
        this.message = ''
        this.modalInitialized = true
      }
    },
    hideModal() {
      this.$refs['modal-feature'].hide()
    },
    async handleSubmit() {
      const self = this
      this.$refs.supportFormValidator.validate()
        .then(async success => {
          if (success) {
            this.isSubmitting = true
            try {
              const apiResponse = await AccountService.sendFeatureRequest({
                email: self.email,
                message: self.message,
                url: window.location.href,
              })
              if (apiResponse.data.success === true) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    text: 'Your message was successfully sent',
                    variant: 'success',
                  },
                })
                this.modalInitialized = false
                this.hideModal()
              }
            } catch (error) {
              console.error(error)
            } finally {
              this.isSubmitting = false
            }
          }
        })
    },
  },
}
</script>
<style>
.suggest-feature-link {
  position: absolute;
  bottom: 0;
  padding: 15px;
  width:100%;
  background:#FFF;
  display:flex;
  justify-content: center;
}
</style>
